<template>
  <div>
    <Header ref="header"/>

    <div class="content article-content">
      <div style="height: 20px"></div>
      <div class="w w1200 clearfix">
        <section class="Title">欢乐九宫格</section>

        <section id="main">
          <div class="egg">
            <div class="Opportunity">你还有<strong><font data-v-969a18fa="" id="chance">{{chance}}</font></strong>次机会</div>
            <div class="eggList">
              <div class="resultTip" v-if="showResult"><b>{{result}}</b></div>
            </div>
            <LuckyGrid
                ref="myLucky"
                width="500px"
                height="500px"
                :rows="4"
                :cols="4"
                :prizes="prizes"
                :blocks="blocks"
                :buttons="buttons"
                :defaultStyle = "defaultStyle"
                @start="startCallback"
                @end="endCallback">
            </LuckyGrid>
          </div>
        </section>

        <section class="InfoBox">
          <div class="winners_user">
            <div class="ico"></div>
            <div id="winners_user">
              <div id="winners_info">
                <ul id="init_data">
                  <li v-html='chanceInfo'></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="relevant-list">
            <div class="award_list_ico">
              <router-link :to="{path:'/marketing/lottery/log'}" target="_blank" tag="a">
                <span></span>
                <strong>抽奖记录</strong>
              </router-link>
            </div>
            <div class="rule_ico">
              <router-link :to="{path:'/marketing/lottery/nineGrid/info'}" target="_blank" tag="a">
                <span></span>
                <strong>规则说明</strong>
              </router-link>
            </div>
          </div>

          <div id="rule_box" style="display:none;" class="prompt_box">
            <div class="column"><span class="close" onclick="easyDialog.close();">X</span><h3>规则说明</h3></div>
            <div class="prompt_info" style="height:200px; overflow-y:scroll;" id="huodong_desc_div">

            </div>
          </div>
        </section>

      </div>
    </div>

    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/header/Index'
import Footer from '@/components/footer/Index'
import {getGrid, getListByType} from '@/api/mall/marketing/lottery/luckDrawGoods'
import {getInfo} from "@/api/mall/marketing/lottery/luckDrawCustomer";
export default {
  name: "index",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      result: '',
      showResult:false,
      chance: 0,
      chanceInfo: '',
      orderAmount: 0,
      usedAmount: 0,

      blocks: [
        { padding: '10px', background: '#869cfa' },
        { padding: '10px', background: '#e9e8fe' },
      ],
      prizesMap:{},
      prizes: [
        // { x: 0, y: 0, fonts: [{ text: '0', top: '20%' }] },
        // { x: 0, y: 1, fonts: [{ text: '11', top: '20%' }] },
        // { x: 0, y: 2, fonts: [{ text: '10', top: '20%' }] },
        // { x: 0, y: 3, fonts: [{ text: '9', top: '20%' }] },
        // { x: 1, y: 0, fonts: [{ text: '1', top: '20%' }] },
        // { x: 1, y: 3, fonts: [{ text: '8', top: '20%' }] },
        // { x: 2, y: 0, fonts: [{ text: '2', top: '20%' }] },
        // { x: 2, y: 3, fonts: [{ text: '7', top: '20%' }] },
        // { x: 3, y: 0, fonts: [{ text: '3', top: '20%' }] },
        // { x: 3, y: 1, fonts: [{ text: '4', top: '20%' }] },
        // { x: 3, y: 2, fonts: [{ text: '5', top: '20%' }] },
        // { x: 3, y: 3, fonts: [{ text: '6', top: '20%' }] },
      ],
      buttons: [ {
        x: 1, y: 1,
        col: 2, row: 2,
        background: '#9c9dd8',
        fonts: [{ text: '开始', top: '47%' }],
      }],
      defaultStyle: {
        background: '#b8c5f2'
      }
    }
  },
  mounted() {
    this.$refs.header.hideCate();
  },
  created() {
    this.getInfo()
    this.getList()
  },
  methods:{
    // 点击抽奖按钮会触发star回调
    startCallback () {

      console.log(this.prizesMap)
      console.log(this.prizesMap.get(3))
      // 调用抽奖组件的play方法开始游戏
      this.$refs.myLucky.play()

      let _t = this
      _t.showResult = false

      getGrid().then(res => {
        let data = res.data
        let index = 997
        if(data.msg == ''){
          if(data.prize!='' && data.prizeName!=''){
            _t.result = '恭喜你，抽中 ' + data.prizeName;
            index = this.prizesMap.get(parseInt(data.prize))
          }else{
            let genRandom = (Math.random() * (999 - 997 + 1) | 0) + 997;
            index = this.prizesMap.get(genRandom)
            _t.result = '很遗憾！您未中奖~~~';
          }
        }else{
          _t.result = data.msg;
          let genRandom = (Math.random() * (999 - 997 + 1) | 0) + 997;
          index =  this.prizesMap.get(genRandom)
        }

        console.log('##########')
        console.log(_t.result)
        console.log(index)
        console.log('##########')

        _t.chance = _t.chance - 1 > 0 ? _t.chance - 1 : 0
        _t.chanceInfo = '你有'+_t.chance+'次红包抽奖机会哦~~~';

        setTimeout(() => {
          // 假设后端返回的中奖索引是0
          // const index = 12
          // 调用stop停止旋转并传递中奖索引
          this.$refs.myLucky.stop(index)
        }, 3000)

        setTimeout(() => {
          _t.showResult = true
        }, 6000)

      });

      // // 模拟调用接口异步抽奖
      // setTimeout(() => {
      //   // 假设后端返回的中奖索引是0
      //   const index = 12
      //   // 调用stop停止旋转并传递中奖索引
      //   this.$refs.myLucky.stop(index)
      // }, 3000)
    },
    // 抽奖结束会触发end回调
    endCallback (prize) {
      console.log(prize)
    },
    getInfo(){
      getInfo().then(res => {
        console.log('##################')
        console.log(res)
        console.log('##################')
        if(res.data){
          this.orderAmount = res.data.orderAmount
          this.usedAmount = res.data.usedAmount

          if((this.orderAmount - this.usedAmount) < 2000){
            this.chance = 0;
            this.chanceInfo = '您不符合红包抽奖要求，再采购<span>'+Math.ceil((2000-(this.orderAmount - this.usedAmount)))+'</span>元，可进行一次红包抽奖哦~~~';
          }else{
            this.chance = Math.trunc((this.orderAmount - this.usedAmount) / 2000);
            this.chanceInfo = '你有'+this.chance+'次红包抽奖机会哦~~~';
          }
        }else{
          this.chance = 0;
          this.chanceInfo = '您不符合红包抽奖要求，再采购<span>2000</span>元，可进行一次红包抽奖哦~~~';
        }

      })
    },
    getList(){
      // getListByType
      getListByType('grid').then(res => {
        let list = res.data
        let prizes = []
        let prizesMap = new Map()

        // prizes.push({ x: 0, y: 0, imgs: [{ src: list[0].GoodsImage, width: '100%',activeSrc: 'http://static.jzybyy.com/luckdraw/active.png'}]})
        // prizes.push({ x: 0, y: 1, imgs: [{ src: list[1].GoodsImage, width: '100%',activeSrc: 'http://static.jzybyy.com/luckdraw/active.png'}]})
        // prizes.push({ x: 0, y: 2, imgs: [{ src: list[2].GoodsImage, width: '100%',activeSrc: 'http://static.jzybyy.com/luckdraw/active.png'}]})
        // prizes.push({ x: 0, y: 3, fonts: [{ text: '谢谢参与', top: '37%' }]})
        // prizes.push({ x: 1, y: 0, imgs: [{ src: list[3].GoodsImage, width: '100%',activeSrc: 'http://static.jzybyy.com/luckdraw/active.png'}]})
        // prizes.push({ x: 1, y: 3, imgs: [{ src: list[4].GoodsImage, width: '100%',activeSrc: 'http://static.jzybyy.com/luckdraw/active.png'}]})
        // prizes.push({ x: 2, y: 0, fonts: [{ text: '谢谢参与', top: '37%'}]})
        // prizes.push({ x: 2, y: 3, imgs: [{ src: list[5].GoodsImage, width: '100%',activeSrc: 'http://static.jzybyy.com/luckdraw/active.png'}]})
        // prizes.push({ x: 3, y: 0, imgs: [{ src: list[6].GoodsImage, width: '100%',activeSrc: 'http://static.jzybyy.com/luckdraw/active.png'}]})
        // prizes.push({ x: 3, y: 1, imgs: [{ src: list[7].GoodsImage, width: '100%',activeSrc: 'http://static.jzybyy.com/luckdraw/active.png'}]})
        // prizes.push({ x: 3, y: 2, fonts: [{ text: '谢谢参与', top: '37%' }] })
        // prizes.push({ x: 3, y: 3, imgs: [{ src: list[8].GoodsImage, width: '100%',activeSrc: 'http://static.jzybyy.com/luckdraw/active.png'}]})



        prizes.push({ x: 0, y: 0, imgs: [{ src: list[0].goodsImage, width: '100%',activeSrc: list[0].goodsImageSelected}]})
        prizes.push({ x: 1, y: 0, imgs: [{ src: list[3].goodsImage, width: '100%',activeSrc: list[3].goodsImageSelected}]})
        prizes.push({ x: 2, y: 0, fonts: [{ text: '谢谢参与', top: '37%'}]})
        prizes.push({ x: 3, y: 0, imgs: [{ src: list[6].goodsImage, width: '100%',activeSrc: list[6].goodsImageSelected}]})
        prizes.push({ x: 3, y: 1, imgs: [{ src: list[7].goodsImage, width: '100%',activeSrc: list[7].goodsImageSelected}]})
        prizes.push({ x: 3, y: 2, fonts: [{ text: '谢谢参与', top: '37%' }] })
        prizes.push({ x: 3, y: 3, imgs: [{ src: list[8].goodsImage, width: '100%',activeSrc: list[8].goodsImageSelected}]})
        prizes.push({ x: 2, y: 3, imgs: [{ src: list[5].goodsImage, width: '100%',activeSrc: list[5].goodsImageSelected}]})
        prizes.push({ x: 1, y: 3, imgs: [{ src: list[4].goodsImage, width: '100%',activeSrc: list[4].goodsImageSelected}]})
        prizes.push({ x: 0, y: 3, fonts: [{ text: '谢谢参与', top: '37%' }]})
        prizes.push({ x: 0, y: 2, imgs: [{ src: list[2].goodsImage, width: '100%',activeSrc: list[2].goodsImageSelected}]})
        prizes.push({ x: 0, y: 1, imgs: [{ src: list[1].goodsImage, width: '100%',activeSrc: list[1].goodsImageSelected}]})


        prizesMap.set(list[0].id, 0);
        prizesMap.set(list[1].id , 11);
        prizesMap.set(list[2].id , 10);
        prizesMap.set(list[3].id , 1);
        prizesMap.set(list[4].id , 8);
        prizesMap.set(list[5].id , 7);
        prizesMap.set(list[6].id , 3);
        prizesMap.set(list[7].id , 4);
        prizesMap.set(list[8].id , 6);
        prizesMap.set(999 , 2);
        prizesMap.set(998 , 5);
        prizesMap.set(997 , 9);


        this.prizes = prizes
        this.prizesMap = prizesMap
      })
    }
  }
}
</script>

<style scoped>
.w{
  color:#333333; background:#f4f0ca;
}

.Title{width:100%; height:48px; font:18px/48px "Microsoft YaHei"; color:#fff; background:#d03237; text-align:center; position:relative;}

#main{width:100%; height:602px; background:#e8383d; position:relative;}
#main .bg{width:100%; height:58px; background:#f4f0ca; position:absolute; z-index:1; bottom:0px;}

.egg{width:510px; height:600px; margin:0px auto; padding-top:10px; z-index:2; position:relative;}
.eggList{padding-top:0px; position:relative; width:310px; margin:0px auto;}
.eggbtn{background:url(../../../../assets/marketing/SmashGoldenEggs/egg_1.png) no-repeat bottom; width:158px; height:187px; margin:0px auto; cursor:pointer; position:relative; background-size: 158px 161px;}
.eggbtn span{position:absolute; width:30px; height:60px; left:68px; top:64px; color:#ff0; font-size:42px; font-weight:bold}
.eggbtn.curr{background:url(../../../../assets/marketing/SmashGoldenEggs/egg_2.png) no-repeat bottom;cursor:default;z-index:300; background-size: 158px 161px;}
.eggbtn.curr sup{position:absolute;background:url(../../../../assets/marketing/SmashGoldenEggs/img-4.png) no-repeat;width:232px; height:181px;top:-36px;left:-34px;z-index:800;}
.hammer{background:url(../../../../assets/marketing/SmashGoldenEggs/img-6.png) no-repeat; width:74px; height:87px; position:absolute; text-indent:-9999px; z-index:150; left:168px; top:10px;}
.resultTip{position:absolute; background:#ffc ;width:148px;padding:6px;z-index:500;top:200px; left:10px; color:#f60; text-align:center;overflow:hidden;display:block;z-index:500;}
.resultTip b{font-size:14px;line-height:24px;}

.InfoBox{padding:0px 10px;}

/*抽奖机会*/
.Opportunity{width:120px; height:26px; margin:30px auto 0px; margin-bottom: 20px; background:#ba2d31; text-align:center; font:12px/24px "Microsoft YaHei"; color:#fff; border-radius:30px;}

/*中奖人滚动字幕*/
.winners_user{width:auto; height:24px; background:#f2e7b9; margin:0px auto; position:relative; margin-top:10px;}
.winners_user .ico{width:29px; height:24px; position:absolute; left:0px; top:0px; z-index:999; background:url(../../../../assets/marketing/SmashGoldenEggs/winners_user.png) center top no-repeat; background-size: 29px 24px;}
.winners_user #winners_user{width:auto; height:24px; overflow:hidden; position:relative;}
.winners_user #winners_info{position:absolute; left:0px; top:0px;}
.winners_user #winners_info ul{width:100000%; height:24px;}
.winners_user #winners_info li{padding-left:50px; line-height:24px; white-space:nowrap;}
.winners_user #winners_info li span{color:#e8471d; font-weight:bold;}

/*三个按钮*/
.relevant-list{width:100%; height:80px; margin:0px auto; padding:20px 0px; overflow:hidden; position:relative;}
.relevant-list div{width:28%; height:80px; text-align:center; font-size:14px;}
.relevant-list div a{display:block; height:80px;}
.relevant-list div a span{margin-top:15px;}
.relevant-list div a strong{font-weight:normal; display:block; line-height:1;}
.relevant-list .award_list_ico{background:#fff; position:absolute; left:0px; top:20px;}
.relevant-list .award_list_ico span{width:32px; height:32px; display:inline-table; background:url(../../../../assets/marketing/SmashGoldenEggs/relevant-list.png) -37px top no-repeat; background-size:319px 32px;}
.relevant-list .my_list_ico{margin:0px auto; background:#fff;}
.relevant-list .my_list_ico span{width:32px; height:32px; display:inline-table; background:url(../../../../assets/marketing/SmashGoldenEggs/relevant-list.png) -144px top no-repeat; background-size:319px 32px;}
.relevant-list .rule_ico{background:#fff; position:absolute; right:0px; top:20px;}
.relevant-list .rule_ico span{width:32px; height:32px; display:inline-table; background:url(../../../../assets/marketing/SmashGoldenEggs/relevant-list.png) -251px top no-repeat; background-size:319px 32px;}

/*弹框*/
.prompt_box{width:280px; background:#fff; padding:0px 10px 10px;}
.prompt_box .column{height:40px; border-bottom:2px solid #fba10f; margin-bottom:10px; overflow:hidden;}
.prompt_box .column h3{font-size:18px; color:#faa00c; line-height:40px; padding-left:10px; font-weight:normal; display:block;}
.prompt_box .column .close{float:right; height:40px; width:40px; display:block; cursor:pointer; background:url(../../../../assets/marketing/SmashGoldenEggs/close.png) top center no-repeat; text-indent:-9999px;}
.prompt_box .prompt_info{padding:10px 0px; font-size:14px;}
.prompt_box .prompt_info #Noaward{color:#9fa0a0; text-align:center; display:block;}
.prompt_box .prompt_info #Noaward strong{display:block; font-size:16px; color: #444444;}
.prompt_box .prompt_info #theAward{color:#9fa0a0; text-align:center; display:block;}
.prompt_box .prompt_info #theAward strong{display:block; font-size:16px; color: #444444;}
.prompt_box .prompt_info #theAward strong span{color:#fba10f;}

/*奖品列表*/
.award_list{width:100%; height:auto; margin:0px auto;}
.award_list ul{padding:10px;}
.award_list ul li{position:relative; margin-bottom:20px;}
.award_list ul li .ranking{width:32px; height:32px; text-align:center; line-height:32px; position:absolute; left:0px; top:0px; background:#faa113; color:#fff; font-size:18px;}
.award_list ul li.award_1 .ranking{background:#e8471d;}
.award_list ul li img{width:100%; height:150px; border-radius:3px;}
.award_list ul li .award_name{font-size:18px; text-align:center; padding-top:3px; display:block;}

/*我的奖品*/
.my_list{width:100%; height:auto; margin:0px auto;}
.my_list ul{padding:0px 10px;}
.my_list ul li{padding:10px 0px; border-bottom:1px solid #ddddde; overflow:hidden;}
.my_list ul li .award_ico{width:64px; height:64px; float:left; overflow:hidden; border-radius:5px;}
.my_list ul li .award_ico img{width:80px; border-radius:5px;}
.my_list ul li .award_info{float:left; padding-left:10px; font-size:11px; overflow:hidden;}
.my_list ul li .award_info strong{font-size:18px; color:#e8471d; display:block;}
.my_list ul li .award_btn{float:right; display:inline-table; padding:5px 10px; background:#e8471d; font-size:15px; text-align:center; border-radius:5px; margin-top:15px;}
.my_list ul li .award_btn a{color:#fff;}
</style>
